@import "~@/styles/themes.scss";
@import '../../../../styles/themes.scss';

$shadow-big: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
  0 3px 5px 0 rgba(0, 0, 0, 0.1);
$shadow-small: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.1),
  0px 6px 10px rgba(0, 0, 0, 0.08);

@mixin field {
  display: block;
  padding: 17px;
  border-radius: 15px;
  box-shadow: $shadow-big;
  font-size: 14px;
}

.hide {
  display: none;
}

.sectionTitle {
  margin-left: 6px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
}

.upload {
  @include field;
  background-color: $pwa-primary-color;
  color: $pwa-secondary-color;
  border: 6px solid white;
  display: flex;
  align-items: center;
  gap: 14px;
  .uploadIcon {
    font-size: 24px;
  }
}

.uploadError {
  color: $pwa-accent-red;
  margin-top: 8px;
  margin-left: 15px;
  line-height: 20px;
  font-size: 12px;
}

.document {
  @include field;
  position: relative;
  margin-top: 36px;
  color: $pwa-primary-color;

  .documentStatus {
    position: absolute;
    top: -16px;
    right: -10px;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 10px;
    border-radius: 6px;
    box-shadow: $shadow-small;
  }
  .documentStatus::first-letter {
    text-transform: capitalize;
  }
}

.document:hover {
  color: $pwa-primary-color;
}

.documentFeedback {
  @include field;
  margin-top: 10px;
  .documentFeedbackText {
    color: $pwa-grey-2;
  }
  .documentFeedbackReupload {
    margin-top: 10px;
    color: $pwa-accent-blue;
  }
}
.documentRename {
  color: $pwa-accent-blue;
  margin-top: 8px;
  margin-left: 15px;
  line-height: 20px;
  font-size: 12px;
}

.saveCancelContainer {
  display: flex;
  gap: 23px;
  .save {
    @extend .documentRename;
  }
  .cancel {
    color: $pwa-grey-4;
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
  }
}

.documentInput {
  // reset default ipnut style
  all: unset;
  width: 100%;
}

%receivedTraining {
  @include field;
  background-color: $pwa-grey-7;
  border: 6px solid #fff;
  box-sizing: border-box;
  // default 17px - 6px of the border
  padding: 11px;
  margin-top: 20px;
}

.AdminDocument {
  @extend %receivedTraining;
  color: $pwa-accent-blue;
  &:hover {
    color: $pwa-accent-blue;
  }
}

.TrainingLogPreview {
  @extend %receivedTraining;
  color: $admin-grey-2;
  .logDate {
    margin-top: 7px;
    font-size: 10px;
  }
}
