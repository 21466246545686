@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

// .container {
//   padding: 30px;
// }

.topLayOut {
  width: 100%;
  height: 250px;
  flex-shrink: 0;
  background: var(--1-Fill-_Primary, #0a0b09);
}

.eventImage {
  border-radius: 10px;
  width: 90%;
  object-fit: cover;
  height: 100px;
}

.imagePlaceholder {
  object-fit: contain;
  // shade of image background
  background-color: #061115;
  height: 100px;
  border-radius: 10px;
  width: 90%;
}

// width of an image
@media (max-width: 362px) {
  .imagePlaceholder {
    object-fit: cover;
  }
}

.title {
  color: $pwa-grey-1;
  font-size: 24px;
  font-weight: bold;
  font-family: $secondary-font;
}

.toast {
  background-color: $pwa-secondary-color;
  border-radius: 15px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.1),
    0px 6px 10px rgba(0, 0, 0, 0.08);
  padding: 14px 14px 16px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  color: $pwa-grey-1;
  font-size: 18px;
  font-weight: bold;
  font-family: $secondary-font;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;

  .pastEventTitle {
    color: $pwa-accent-blue;
    font-size: 14px;
    font-weight: normal;
    font-family: $secondary-font;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    .pastEventName {
      margin-left: 5px;
      margin-right: 2px;
      line-height: 18px;
    }
  }
}

.eventContainer {
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 25px;
  margin-left: 30px;
  margin-right: 30px;

  .header {
    margin-right: -15px;
    display: flex;
    justify-content: space-between;
  }

  .waitingIcon {
    margin-left: 8px;
    border-radius: 50px;
  }
  .rejectedIcon {
    margin-left: 8px;
    border-radius: 50px;
    padding: 5px;
  }
  .acceptedIcon {
    margin-left: 8px;
    border-radius: 50px;
    padding: 5px;
  }

  .clockContainer {
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px 0px 0px 16px;
    width: 42px;
    height: 32px;
  }

  .time {
    width: 170px;
    font-family: $secondary-font;
    color: $pwa-grey-1;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .eventTitle {
    font-size: 16px;
    color: $pwa-grey-1;
  }

  .location {
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-3;
  }

  .subContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .availabletabSubcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    padding: 10px;
  }

  .icon {
    color: $pwa-secondary-color;
    margin-right: 10px;
  }
}

.activeTab {
  width: 50%;
  cursor: pointer;
  justify-content: space-between;
  border: none;
  border-radius: 10px;
  // margin: 2px;
  & :focus {
    background: green;
  }
}

.inactiveTab {
  width: 50%;
  cursor: pointer;
}

.chooseEventType {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 7px;
  border-radius: 15px;
  //font-size: 12px;
  display: flex;
  margin-bottom: 10px;

  .activeContainer {
    width: 70%;
    background-color: $admin-secondary-color;
    color: #000;
    border-radius: 9px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    margin-right: 3px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-family: $secondary-font;
  }
  .inactiveContainer {
    width: 30%;
    border-radius: 9px;
    color: $admin-grey-2;
    background-color: $pwa-grey-7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    margin-right: 3px;
  }

  .eventTypeText {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.comingUpContainer {
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 75px;

  .time {
    font-family: $secondary-font;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: $pwa-grey-1;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .eventTitle {
    font-size: 16px;
    color: $pwa-grey-1;
  }

  .location {
    font-size: 12px;
    font-weight: bold;
    color: $pwa-grey-3;
  }

  .subContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    color: $pwa-secondary-color;
    margin-right: 10px;
  }
}

.comingUpBottomContainer {
  display: flex;
  position: absolute;
  padding: 20px;
  width: 90%;
  background-color: white;
  left: 5%;
  bottom: -40px;
  border-radius: 15px;
  justify-content: space-evenly;
}

.comingUpBottomContainer {
  display: flex;
  position: absolute;
  padding: 5px;
  width: 90%;
  background-color: white;
  left: 5%;
  bottom: -50px;
  border-radius: 15px;
  justify-content: space-evenly;
}

.comingUpBottomContainer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100vh;

  .comingContainerTitle {
    font-size: 10px;
    font-weight: 800;
  }

  .comingIconContainer {
    display: flex;
    padding: 10px;
    background-color: $pwa-grey-7;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    margin-left: 2px;
    margin-right: 2px;

    &:active {
      background-color: $pwa-secondary-color;
    }
  }

  .comingIcon {
    color: $pwa-grey-2;

    &:active {
      color: white;
    }
  }
}

.shadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.logo {
  text-align: center;
  // margin-top: 60px;
  padding-top: 75px;
}

.homeScreen {
  // padding: 40px;
  // background-color: #F5F5F5;
  // border-radius: 35px;
  // margin-bottom: 30px;
  margin-top: -105px;
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--1-Fill-White, #fff);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1),
    0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

.paragraph {
  align-self: stretch;
  color: var(--1-Fill-Grey-1-Dark, #595959);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.info {
  margin-top: 24px;
  padding: 16px 20px;
  border-radius: 30px;
  border: 6px solid #fff;
  background: #fafbdb;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1),
    0px 1px 18px 0px rgba(0, 0, 0, 0.1), 0px 6px 10px 0px rgba(0, 0, 0, 0.08);
}

.infoTitle {
  color: var(--1-Fill-_Primary, #0a0b09);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.infoTile {
  display: flex;
  width: 100%;
  padding: 16px 14px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 0.5px solid var(--1-Fill-Grey-3-Light, #bfbfbf);
  background: #fff;
  margin-bottom: 14px;
}

.infoEvent {
  margin-top: -25px;
  padding: 16px 20px;
  border-radius: 20px;
  border: 6px solid #fff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1),
    0px 1px 18px 0px rgba(0, 0, 0, 0.1), 0px 6px 10px 0px rgba(0, 0, 0, 0.08);
}

.pastEvent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  text-decoration: none; /* Optional: To remove underline from the link */
}

.apostoScreen {
  margin-top: 30px;
  padding: 40px;
  background-color: #262829;
  border-radius: 35px;
  margin-bottom: 30px;
}

.placeholderText {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.placeholderSubText {
  // margin-top: 28px;
  font-size: 20px;
  text-align: left;
}

.introduction {
  margin-top: 40px;
  padding: 45px;
  background-color: #262829;
  border-radius: 35px;
  color: #fff;
}

.IntroductionplaceholderText {
  font-size: 25px;
  font-weight: 800;
  text-align: center;
  color: #1890ff;
}

.topspace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: palevioletred;
  // margin: 40px 0;
}

.choosePlatform {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 7px;
  border-radius: 10px;
  //font-size: 12px;
  display: flex;
  margin-bottom: 10px;
  //  border:2px solid gray;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  .activeOS {
    width: 50%;
    background-color: $admin-secondary-color;
    color: #000;
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inactiveOS {
    width: 50%;
    color: $admin-grey-2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.comingUpButton {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  margin-top: 4.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 18px;
  width: 10rem;
  color: white;
  background-color: green;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: 3px solid #ffffff;
  &:focus,
  &:hover {
    border: 3px solid white !important;
    box-shadow: none;
    border-color: white;
    color: #fff;
  }

  &:hover {
    box-shadow: none;
    color: #fff;
    cursor: pointer;
  }

  :global {
    .ant-input-number-focused {
      border: 2px solid $admin-secondary-color !important;
      box-shadow: none;
      border-color: $admin-secondary-color;
    }
  }
}

.eventOverviewDrawer {
  :global {
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-content {
      // fixes the ant-drawer-content being larger than window size
      // and creating a scroll
      overflow: visible;
    }
  }
}

.upcoming__text {
  width: 182px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0a0b09;
}

.checkInContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  .qrCodeIcon {
    width: 36px;
    height: 36px;
    font-size: 26px;
    border-radius: 10px;

    background-color: $near-black;
    color: #fcea25;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
