@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.container {
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  bottom: 0;
  height: 86px;
  background-color: $pwa-primary-color;
  width: 100%;
}

.button {
  display: flex;
  color: #FFFFFF50;
  justify-content: center;
  align-items: center;
  width: 100vh;

  &:hover {
    color: $pwa-secondary-color;
    border-top: 4px solid $pwa-secondary-color;
  }
  
  &:active {
    color: $pwa-secondary-color;
    border-top: 4px solid $pwa-secondary-color;
  }
}

.buttonSelected {
  color: $pwa-secondary-color;
  border-top: 4px solid $pwa-secondary-color;
}

.alertIndicator {
  position: absolute;
  right: 1px;
  bottom: 9px;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: #FB4953;
}
