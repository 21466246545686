@import "~@/styles/themes.scss";
.container {
  // height: 87vh;
  overflow: scroll;
  padding-bottom: 86px;
}

.avataru{
  // width: 100%;
  // background-color: peru;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 25px;
  top:30px;

  .avataru-name{
    
  }
}
