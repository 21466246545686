@import "~@/styles/themes.scss";
.header {
  color: white;
  font-family: $primary-font;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;

  margin-bottom: 24px;
}

.background {
  position: fixed; /* Make sure it stays in one spot */
  // top: 0;
  // left: 0;
  width: 100dvw; /* Full viewport width */
  height: 100dvh; /* Full viewport height */
  background-color: black;
  padding: 24px;

  display: flex;
  flex-direction: column;
}

.qrReaderContainer {
  flex-grow: 1;
}

.qrReaderClass {
  width: 100%;
  height: 100%;
  div {
    height: 100%;
    border-radius: 50px;
  }
  video {
    object-fit: cover; /* Make sure the video covers the whole area */
  }
}

.closeButton {
  all: unset;
  border: 2px solid white;
  border-radius: 10px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  background-color: #000;
  cursor: pointer;

  .closeButtonIcon {
    font-size: 24px;
  }
}

.closeButton:hover {
  background-color: $pwa-grey-1;
}

.closeButton:active {
  transform: scale(0.97);
}
