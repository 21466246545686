@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

.container {
  margin: 60px 0px 20px 0px;
  padding: 20px;

  .topContainer {
    .title {
      font-size: 24px;
      font-weight: 800;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .description {
      font-size: 14px;
    }
    .progressContainer {
      display: flex;
      align-items: center;
    }
  }

  .middleContainer {
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      font-weight: 800;
    }

    .completed {
      font-size: 14px;
      font-weight: 600;
      color: $pwa-accent-blue;
    }
  }

  .emptyModuleTitle {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }

  .emptyModuleDescription {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    margin: 10px 30px 0 30px;
  }
}

.drawerContainer {
  padding: 0;
  :global {
    .ant-drawer-body {
      padding: 0;
    }
  }
  .content {
    padding: 20px;
    margin-top: 10px;

    .emptyModuleDescription {
      font-size: 16px;
      font-weight: 500;
      // text-align: justify;
    }
  }
}

.drawerHeader {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: $pwa-primary-color;
  width: 100%;

  .title {
    color: white;
    font-size: 16px;
    padding-right: 10px;
  }

  .backIcon {
    color: white;
    font-size: 24px;
    margin-right: 15px;
  }
}

.progressCircle {
  background-color: white;
  left: 0px;

  :global {
    .ant-progress-text {
      font-weight: bold;
      // opacity: 0.4;
      .topText {
        font-size: 18px;
      }
      .bottomText {
        font-size: 10px;
      }
    }
  }
}

.tabSelectTraining {
  display: flex;
  justify-content: space-around;
  margin-bottom: 18px;
}

.trainingInfo {
  background-color: $pwa-grey-7;
  border-radius: 15px;
  padding: 12px;
  margin: 20px 0px;
  .trainingInfoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .trainingInfoIcons {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .trainingInfoNotification {
    color: $pwa-accent-blue;
    font-size: 17px;
  }
  h3,
  p {
    color: $pwa-grey-2;
    padding: 0px;
    margin: 0px;
  }
  h3 {
    font-size: 14px;
    font-weight: 700;
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
}
