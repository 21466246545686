@import "~@/styles/themes.scss";
.checkDrawerContent {
  // Let the buttons grow?
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  line-height: normal;

  // Make sure children take full width
  > * {
    width: 100%;
  }
}

.checkTitle {
  text-align: center;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 700;
}

.roundDrawer {
  :global {
    .ant-drawer-content {
      border-radius: 39px 39px 0 0;
    }
  }
}

.eventInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.eventText {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  gap: 8px;

  font-family: $primary-font;
  color: $near-black;

  .eventName {
    font-size: 16px;
  }

  .eventLocation {
    color: $pwa-grey-3;
    font-size: 12px;
    font-weight: 700;
  }

  .eventDate {
    font-size: 16px;
    font-weight: 700;
  }
}

.yellowButton {
  height: 59px;

  background-color: $pwa-secondary-color;
  color: $near-black;
  border: none;
  border-radius: 15px;
  // padding: 10px 20px;

  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;

  box-shadow: $admin-main-shadow;

  &:hover {
    background-color: darken($pwa-secondary-color, 10%);
    color: $near-black;
  }
  &:focus {
    background-color: $pwa-secondary-color;
    color: $near-black;
  }
}

.blueButton {
  font-family: $secondary-font;
  font-weight: 600;
}

.eventImageContainer {
  // Shared styles for both .eventImage and .imagePlaceholder
  .eventImage,
  .imagePlaceholder {
    border-radius: 10px;
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .imagePlaceholder {
    object-fit: contain; // Will be overridden for smaller screens below
    background-color: #061115; // shade of image background
  }

  // Media query for smaller screens
  @media (max-width: 362px) {
    .imagePlaceholder {
      object-fit: cover;
    }
  }
}

.roundIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 100%;

  color: white;
  font-size: 33px;
}

.checkInIcon {
  background-color: $admin-accent-green;
}

.checkOutIcon {
  background-color: #fb4953;
}

.notScheduledIcon {
  background-color: $pwa-accent-red;
}

.errorIcon {
  background-color: $admin-accent-yellow;
}

.titleAndIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
