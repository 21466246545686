@import "~@/styles/themes.scss";
@import '../../styles/themes.scss';

.TabSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 700;
  font-family: $admin-title-font;

  .selected {
    color: #0a0b09;
  }

  .notSelected {
    color: $pwa-grey-4;
  }

  .yellowLine {
    width: 120%;
    border-radius: 2px 2px 0px 0px;
    border: 2px solid yellow;
  }
}
